/**
* Generated automatically at built-time (2024-12-04T12:35:02.038Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "vilken-vinkyl-passar-i-mitt-kok",templateKey: "sites/88-e051ecf3-d991-4e29-b55e-ba98eb91055c"};